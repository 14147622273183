
export const PICKERS_TEMPLATE = `
    <div class="bw bw-search">
        <div class="bw-pickers">
            <h3><strong>[search_header]</strong></h3>
            <div class="bw-pickers-row">
                <div class="bw-pickers__picker-col">
                    <label for="bw-pickers-picker-datepicker"><span class="bw-desktop-only">Tour </span> Date</label>
                    <button id="bw-pickers-picker-datepicker" class="bw-pickers__picker bw-pickers__picker--datepicker bw-pickers__button" data-touched="0">
                        <span class="bw-pickers__picker-text bw-pickers__datepicker-display"></span>
                        <span class="bw-pickers__picker-toggle bw-icon--chevron-down"></span>
                    </button>
                </div>
                <div class="bw-pickers__picker-col bw-pickers__picker-col--gp">
                    <label for="bw-pickers-picker-guestpicker">Passengers</label>
                    <button id="bw-pickers-picker-guestpicker" class="bw-pickers__picker bw-pickers__picker--guestpicker bw-pickers__button" 
                        data-guests-adult="2" 
                        data-guests-child="0" 
                        data-guests-toddler="0" 
                        data-touched="0" 
                        data-ts-popup--container=".bw-pickers__picker-col--gp"
                        data-ts-popup-open="bwgp-widget">
                        <span class="bw-pickers__picker-text bw-pickers__guestpicker-display">2 Adult</span>
                        <span class="bw-pickers__picker-toggle bw-icon--chevron-down"></span>
                    </button>
                </div>
                <div class="bw-pickers__picker-col bw-pickers__picker-col--vessel">
                    <label>Type of Boat</label>
                    <div class="bw-pickers-picker-vessel">
                        <label for="bw-pickers-picker-vessel--catamaran" class="bw-pickers__picker-text">
                            <input type="checkbox" id="bw-pickers-picker-vessel--catamaran" name="vessel_type" value="catamaran"><span>65' Catamaran<br>Max 49 Guests<br>Ages 3+</span>
                        </label>
                        <label for="bw-pickers-picker-vessel--raft" class="bw-pickers__picker-text">
                            <input type="checkbox" id="bw-pickers-picker-vessel--raft" name="vessel_type" value="raft"><span>24' Raft<br>Max 14 Guests<br>Ages 6+</span>
                        </label>
                    </div>
                </div>
                <a href="#" class="bw-pickers__btn-submit bw-button bw-pickers__picker-col">Check Availability</span></a>
                <div class="bw-sail-bg"></div>
            </div>
        </div>
    </div>
`;