export const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
}

// force the first letter to be capitalized and the rest lower case
// allow 3rd letter to be captialized (i.e. McDonald)
export const force_default_name_capitalization_format = (s) => {
    if (typeof s !== 'string') return '';
    let new_s = '';
    let is_McDonald_format = true;
    for (let i = 0; i < s.length; i++) {
        if ( i == 0 ) { 
            // force upper
            new_s += s.charAt(i).toUpperCase();
        }
        else {
            // force lower
            new_s += s.charAt(i).toLowerCase();
        }

        if (is_McDonald_format) {
            if ( i == 0 || i == 2) {
                if (s.charAt(i) != s.charAt(i).toUpperCase()) {
                    is_McDonald_format = false;
                }
            }
            else {
                if (s.charAt(i) != s.charAt(i).toLowerCase()) {
                    is_McDonald_format = false;
                }
            }
        }

    }
    return is_McDonald_format ? s : new_s;
}

export const is_empty = (s) => {
    if (s == undefined) {
        return true;
    }

    if (s == null) {
        return true;
    }

    if (s == '') {
        return true;
    }
}

export const strip_tags = (input, allowed) => { // eslint-disable-line camelcase
    //  discuss at: https://locutus.io/php/strip_tags/
    // original by: Kevin van Zonneveld (https://kvz.io)
    // improved by: Luke Godfrey
    // improved by: Kevin van Zonneveld (https://kvz.io)
    //    input by: Pul
    //    input by: Alex
    //    input by: Marc Palau
    //    input by: Brett Zamir (https://brett-zamir.me)
    //    input by: Bobby Drake
    //    input by: Evertjan Garretsen
    // bugfixed by: Kevin van Zonneveld (https://kvz.io)
    // bugfixed by: Onno Marsman (https://twitter.com/onnomarsman)
    // bugfixed by: Kevin van Zonneveld (https://kvz.io)
    // bugfixed by: Kevin van Zonneveld (https://kvz.io)
    // bugfixed by: Eric Nagel
    // bugfixed by: Kevin van Zonneveld (https://kvz.io)
    // bugfixed by: Tomasz Wesolowski
    // bugfixed by: Tymon Sturgeon (https://scryptonite.com)
    // bugfixed by: Tim de Koning (https://www.kingsquare.nl)
    //  revised by: Rafał Kukawski (https://blog.kukawski.pl)
    //   example 1: strip_tags('<p>Kevin</p> <br /><b>van</b> <i>Zonneveld</i>', '<i><b>')
    //   returns 1: 'Kevin <b>van</b> <i>Zonneveld</i>'
    //   example 2: strip_tags('<p>Kevin <img src="someimage.png" onmouseover="someFunction()">van <i>Zonneveld</i></p>', '<p>')
    //   returns 2: '<p>Kevin van Zonneveld</p>'
    //   example 3: strip_tags("<a href='https://kvz.io'>Kevin van Zonneveld</a>", "<a>")
    //   returns 3: "<a href='https://kvz.io'>Kevin van Zonneveld</a>"
    //   example 4: strip_tags('1 < 5 5 > 1')
    //   returns 4: '1 < 5 5 > 1'
    //   example 5: strip_tags('1 <br/> 1')
    //   returns 5: '1  1'
    //   example 6: strip_tags('1 <br/> 1', '<br>')
    //   returns 6: '1 <br/> 1'
    //   example 7: strip_tags('1 <br/> 1', '<br><br/>')
    //   returns 7: '1 <br/> 1'
    //   example 8: strip_tags('<i>hello</i> <<foo>script>world<</foo>/script>')
    //   returns 8: 'hello world'
    //   example 9: strip_tags(4)
    //   returns 9: '4'
  
    // const _phpCastString = require('../_helpers/_phpCastString')
  
    // making sure the allowed arg is a string containing only tags in lowercase (<a><b><c>)
    allowed = (((allowed || '') + '').toLowerCase().match(/<[a-z][a-z0-9]*>/g) || []).join('')
  
    const tags = /<\/?([a-z0-9]*)\b[^>]*>?/gi
    const commentsAndPhpTags = /<!--[\s\S]*?-->|<\?(?:php)?[\s\S]*?\?>/gi
  
    // let after = _phpCastString(input)
    let after = input
    // removes tha '<' char at the end of the string to replicate PHP's behaviour
    after = (after.substring(after.length - 1) === '<') ? after.substring(0, after.length - 1) : after
  
    // recursively remove tags to ensure that the returned string doesn't contain forbidden tags after previous passes (e.g. '<<bait/>switch/>')
    while (true) {
        const before = after
        after = before.replace(commentsAndPhpTags, '').replace(tags, function ($0, $1) {
            return allowed.indexOf('<' + $1.toLowerCase() + '>') > -1 ? $0 : ''
        })

        // return once no more tags are removed
        if (before === after) {
            return after
        }
    }
}

// trim the max length, but don't split up words
export const trim_string = (str, max_length, elipsis) => {
    if (!str) {
        return str;
    }
    let trimmed_name = str.substr(0, max_length);
    //re-trim if we are in the middle of a word
    trimmed_name = trimmed_name.substr(0, Math.min(trimmed_name.length, trimmed_name.lastIndexOf(' ')));
    // if less than the max_length, need to just cut it off.
    if (!trimmed_name) {
        let elipsis_len = elipsis ? elipsis.length : 0;
        trimmed_name = str.substr(0, max_length - elipsis_len);
    }

    if (str.length > max_length) {
        trimmed_name += elipsis;
    }
    
    return trimmed_name;
}

var dummy_el;
export const esc_html = (str, skip_quotes) => {
    if (!dummy_el || dummy_el.length == 0) {
        dummy_el = $("<div>");
    }
    
    if (typeof skip_quotes === 'undefined' || !skip_quotes) {
        return dummy_el.text(str).html().replace(/"/g, '&quot;');
    }
    return dummy_el.text(str).html();
}