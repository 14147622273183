import { get as _get_tours } from 'data/tour';
import { get_for_booking as _get_trips } from 'data/tour-vessel';
import { 
    get as get_cache, 
    cache, 
    remove as remove_cache, 
    remove_key_with_prefix
} from 'utils/cache';
import { strip_tags } from 'utils/strings';

export const CONTACT_US_URL = 'https://www.napali.com/about/';
export const LOGO_URL = 'https://prod-ca-public.s3.us-west-1.amazonaws.com/CA-sailing-logo.png';

export const get_tours = (opts) => {
    opts = opts || {};

    let d = $.Deferred();
    let fields = 'id,name,tour_url,tour_info_url,category_id,display_order,highlights_bw,intro,description,bw_v2_image_url,checkin_minutes,end_date_display,tags';

    if (!opts.id) {
        let cached = get_cache('tours');
        if (cached != false) {
            return d.resolve(cached);
        }
    }
    else {
        fields += ',res_complete_partial,embed_video_url,embed_video2_url';
    }

    _get_tours($.extend({
        fields: fields,
        on_done: function(json) {
            if (!opts.id) {
                cache('tours', json);
            }
            return d.resolve(json);
        }
    }, opts));

    return d.promise();
}

// this only returns the data, not the full json response
// when cached _get_trip returns only data, so this function only returns only data when not cached.
export const get_trip = (trip_id) => {
    let d = $.Deferred();

    if (!trip_id || trip_id == 0) {
        d.resolve(null);
        return d.promise();
    }

    let cached = get_cache('tour-vessel-detail-' + trip_id);
    if (cached != false) {
        d.resolve(cached);
        return d.promise();
    }

    let params = {
        id: trip_id,
        on_done: function(json) {
            if (json.success) {
                cache('tour-vessel-detail-' + trip_id, json.data);
                return d.resolve(json.data);
            }
            else {
                // failed to get a trip, usually means it's no longer available
                // clear booking-tours
                remove_cache('tour-vessel-detail-' + trip_id);
                remove_key_with_prefix('booking-tours-');
            }
            return d.resolve(null);
        }
    };
    
    _get_trips(params);

    return d.promise();
}

/**
 * If obj is null/empty, remove cart
 * Storing in LocalStorage in case data to save (# of passengers) is too large for a cookie.
 */
function set_cart(obj) {
    cart = null;
    if (!obj) {
        localStorage.removeItem('ca-cart');
        return;
    }
    localStorage.setItem('ca-cart', JSON.stringify(obj));
}
export const merge_cart = (newObj) => {
    cart = null;

    let result = get_cart();

    if (!result || typeof result !== 'object') {
        result = {};
    }
    
    // Overwrite properties
    for (let key in newObj) {
        if (newObj.hasOwnProperty(key)) {
            result[key] = newObj[key];
        }
    }

    set_cart(result);
}
export const clear_cart = () => {
    cart = null;
    set_cart(null);
}

let cart = null;
export const get_cart = () => {
    if (cart == null) {
        let data = localStorage.getItem('ca-cart');
        if (typeof data === 'undefined' || data === null) {
            return false;
        }
        cart = sanitize_cart_data(JSON.parse(data));
    }
    if (cart.expiration_time) {
        if (new Date() > new Date(cart.expiration_time)) {
            return false;
        }
    }

    return $.extend(true, {}, cart);
}

/**
 * Transaction info (res complete info) is only stored in SessionStorage.
 */
export const set_transaction = (obj) => {
    if (!obj) {
        sessionStorage.removeItem('ca-tx');
        return;
    }
    sessionStorage.setItem('ca-tx', JSON.stringify(obj));
}

export const get_transaction = () => {
    let data = sessionStorage.getItem('ca-tx');
    if (typeof data === 'undefined' || data === null) {
        return false;
    }
    return sanitize_tx_info(JSON.parse(data));
}

export const sanitize_cart_data = (data) => {
    let cart = {};
    if (data.amount_only) {
        cart = { amount_only: Number(data.amount_only) };
        if (data.amount_only_taxes) {
            cart.amount_only_taxes = Number(data.amount_only_taxes);
        }
        if (data.amount_only_fees) {
            cart.amount_only_fees = Number(data.amount_only_fees);
        }
    }

    if (data.is_child) {
        cart.is_child = data.is_child.map(str => (str === 'on' ? 'on' : 'off'));
    }

    const int_fields = [
        'adult',
        'child',
        'toddler',
        'trip_id',
        'tour_id',
        'filter_tour_id',
        'contact_id',
        'passenger_id',
        'hotel_id',
        'gift_certificate_id',
        'expiration_time',
        'is_gift',
        'opt_in',
        'is_charter'
    ];

    for (let i = 0; i < int_fields.length; i++) {
        let key = int_fields[i];
        if (key in data) {
            if (Array.isArray(data[key])) {
                cart[key] = data[key].map(str => (parseInt(str) || 0));
            }
            else if (typeof data[key] === "boolean") {
                cart[key] = data[key];
            }
            else if (typeof data[key] !== 'undefined') {
                cart[key] = parseInt(data[key]) || 0;
            }
        }
    }

    const text_fields = [
        'first_name',
        'last_name',
        'food_preference',
        'email',
        'phone',
        'promo_code',
        'hotel_name',
        'reservation_group_name',
        'start_time',
        'vessel_type',
        'reservation_note',
        'contact_method'
    ];
    for (let i = 0; i < text_fields.length; i++) {
        let key = text_fields[i];
        if (key in data) {
            if (Array.isArray(data[key])) {
                cart[key] = data[key].map(str => strip_tags(str));
            }
            else if (typeof data[key] == "boolean") {
                cart[key] = data[key];
            }
            else {
                cart[key] = strip_tags(data[key]);
            }
        }
    }

    return cart;
}


export const sanitize_tx_info = (data) => {
    let cart = {};
    const int_fields = [
        'trip_id',
        'tour_id',
        'reservation_id',
        'gift_certificate_id',
        'adult_count',
        'child_count',
    ];

    for (let i = 0; i < int_fields.length; i++) {
        let key = int_fields[i];
        if (key in data) {
            if (Array.isArray(data[key])) {
                cart[key] = data[key].map(str => (parseInt(str) || 0));
            }
            else {
                cart[key] = parseInt(data[key]) || 0;
            }
        }
    }

    const text_fields = [
        'name',
        'email',
        'reservation_number',
        'tour_name',
        'payment_created_time',
        'first_name',
        'last_name',
        'food_preference',
        'cc_last4',
    ];
    for (let i = 0; i < text_fields.length; i++) {
        let key = text_fields[i];
        if (key in data) {
            if (Array.isArray(data[key])) {
                cart[key] = data[key].map(str => strip_tags(str));
            }
            else {
                cart[key] = strip_tags(data[key]);
            }
        }
    }
    if (data.prices) {
        cart.prices = {};
        for (let key in data.prices) {
            if (key == 'promo_code') {
                cart.prices[key] = strip_tags(data.prices[key])
            }
            else if (key == 'promo_code_id') {
                cart.prices[key] = parseInt(data.prices[key]) || 0;
            }
            else {
                cart.prices[key] = isNaN(data.prices[key]) ? '0.00' : Number(data.prices[key]).toFixed(2);
            }
        }
    }
    if (data.payment_amount) {
        cart.payment_amount = isNaN(data.payment_amount) ? '0.00' : Number(data.payment_amount).toFixed(2);
    }

    if (data.is_child) {
        cart.is_child = data.is_child.map(str => (str === 'on' ? 'on' : 'off'));
    }
    
    return cart;
}
