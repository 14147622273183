import { 
    CLOSE_BTN_TEMPLATE, 
    CONTACT_INFO_FIELDS,
    NAV_TEMPLATE,
    SELECTED_TOUR_TEMPLATE,
    FOOTER_LOGO,
    ERROR_MESSAGE,
    LINK_EMAIL,
    LINK_PHONE
} from 'booking-widget/v2/templates/shared';


export const PASSENGER_ROW = `
<div class="bw-passengers__row bw-row">
    <div class="bw-passengers__col bw-flex-lg-basis-33 bw-mb--15">
        <input type="hidden" name="is_child" value="[is_child]">
        <label for="first-name-[idx]" class="bw-sr">[is_child_label] First Name</label>
        <input
            placeholder="[is_child_label] First Name[required_asterisk]"
            type="text"
            id="first-name-[idx]"
            data-contact-idx="[idx]"
            data-target="first-name-[idx]"
            name="first_name"
            value=""
            autocomplete="off"
            [first_name_atts]
        />
        <span class="bw-warning" style="display:none"></span>
    </div>
    <div class="bw-passengers__col bw-flex-lg-basis-33 bw-mb--15">
        <label for="last-name-[idx]" class="bw-sr">[is_child_label] Last Name</label>
        <input
            placeholder="[is_child_label] Last Name[required_asterisk]"
            type="text"
            id="last-name-[idx]"
            data-contact-idx="[idx]"
            data-target="last-name-[idx]"
            name="last_name"
            value=""
            autocomplete="off"
            [last_name_atts]
        />
        <span class="bw-warning" style="display:none"></span>
    </div>
    <div class="bw-passengers__col bw-flex-lg-basis-33 bw-mb--15 bw-food-preferences">
        <span class="bw-d-block">[food_pref_intro]</span>
        <span>[food_preferences]</span>
    </div>
</div>
`;

export const FOOD_PREFERENCE = `
    <label for="bw-food_preference-[idx]-[food_pref_key]"><input type="checkbox" id="bw-food_preference-[idx]-[food_pref_key]" name="food_preference" value="[food_pref_value]" data-contact-idx="[idx]" data-target="food-preference-[idx]-[food_pref_value]" /> <span>[food_pref_value]</span></label><a href="#" data-ts-modal-open="[food_pref_modal_id]" class="bw-food-menu-link [hide_show_class]">?</a>
`;

export const INFO_TEMPLATE = `
<div class="bw bw-info">
    <div class="bw-header">
        ${NAV_TEMPLATE}
        ${CLOSE_BTN_TEMPLATE}
        <h1 class="bw-gc-hide bw-text-center bw-mb--0">Passenger <span class="bw-mobile-only">Info</span><span class="bw-desktop-only">Information</span></h1>
        <h1 class="bw-gc-show bw-text-center bw-mb--0">Gift Certificate</h1>
    </div>
    <div class="bw-body">
        ${SELECTED_TOUR_TEMPLATE}
        <p class="bw-best-price">
            You're booking direct with Capt Andy's - <span>Best Price Guaranteed</span>
        </p>
        <div class="bw-form-wrap">
            <div class="bw-gift-promo bw-charters-hide bw-gc-hide">
                <h2>Gift Certificate or Promo Code?</h2>
                <p>If you have a gift certificate or promo code please enter it here and your pricing will be updated.  Otherwise leave blank.</p>
                <form class="bw-form bw-form--row bw-form--update-pricing bw-gift-promo-form" action="">
                    <input type="text" name="gift_promo_code" placeholder="Gift Certificate Number / Promo Code" class=" bw-flex-lg-basis-60" required data-target="gift-promo-code"><input type="submit" class="bw-button bw-form-submit bw-flex-lg-basis-40 bw-fw-normal bw-fs--md" value="Update Pricing">
                    <input type="hidden" name="gift_certificate_id" data-target="gift-certificate-id">
                    <input type="hidden" name="promo_code_id" data-target="promo-code-id">
                </form>
                <div class="bw-errors bw-gift-promo__errors"><div class="bw-errors__list"></div></div>
                <div class="bw-gift-promo__verified bw-gift-promo__verified--promo bw-mt--15" style="display:none">
                    Promo Code Verified: <span class="bw-gift-promo__code bw-gift-promo__code--promo" data-target="promo-code"></span><a href="#" class="bw-gift-promo__remove--promo">clear</a>
                </div>
                <div class="bw-gift-promo__verified bw-gift-promo__verified--gift bw-mt--15" style="display:none">
                    Gift Code Verified: <span class="bw-gift-promo__code bw-gift-promo__code--gift" data-target="gift-code"></span><a href="#" class="bw-gift-promo__remove--gift">clear</a>
                </div>
            </div>
            ${ERROR_MESSAGE}

            <form class="bw-form bw-form--info" action="" autocomplete="off">
                <h2 class="bw-mt--30 bw-charters-hide bw-gc-hide">Passenger Names</h2>
                <div class="bw-passengers bw-charters-hide bw-gc-hide"></div>

                <h2 class="bw-mt--30"><span class="bw-gc-hide">Primary</span> Contact Info</h2>
                ${CONTACT_INFO_FIELDS}

                <label for="hotel-id" class="bw-sr bw-gc-hide">Hotel</label>
                <input type="text" name="hotel_id" 
                    data-autocomplete-field="hotel_id" 
                    data-autocomplete-key="hotel" 
                    data-target="hotel-id"
                    style="display:none"
                />
                <input aria-label="Hotel" type="text" name="hotel_name" class="autocomplete bw-gc-hide"
                    id="hotel-id" 
                    data-autocomplete-key="hotel"
                    data-autocomplete-field="name"
                    data-target="hotel-name"
                    placeholder="Accommodation on Kauai" 
                    autocomplete="off"
                />
                <div class="bw-charters-hide bw-gc-hide">
                    <h2 class="bw-mt--30">Traveling With a Group?</h2>
                    <p class="bw-catamaran-only">Contact our office for accommodations if you are traveling with a group, and the combined total is more than 30 passengers. Mahalo!</p>
                    <p>This tour is offered on multiple vessels.  Please let us know if you are traveling with someone who made a separate reservation. We'll be sure to get you on the same boat!  Please contact our office at least 24 hours prior to departure if you don't know your party's name or group's name.</p>
                    <p>Office: ${LINK_EMAIL} or ${LINK_PHONE}</p>
                    <div class="bw-row">
                        <div style="flex-shrink: 0;">
                            <label class="show" for="bw-enable-group-name"><input type="checkbox" id="bw-enable-group-name"> Yes, I am traveling with a group</label>
                        </div>
                        <div class="bw-col">
                            <label for="bw-group-name" class="bw-sr">Group Name</label>
                            <input type="text" name="reservation_group_name" placeholder="Group Name" id="bw-group-name" data-target="reservation-group-name" disabled="disabled">
                        </div>
                    </div>
                </div>
                <div class="bw-charters-requests"></div>

                <input type="submit" class="bw-button bw-form-submit bw-mt--40" data-original="Continue to Payment" value="Continue to Payment">
            </form>
        </div>
    </div>
    <div class="bw-footer">
        ${FOOTER_LOGO}
    </div>
</div>

<div class="bw-food-preference-popup-container" style="display: none"></div>
`;

export const CHARTERS_REQUESTS = `
    <h2 class="bw-mt--30 bw-mb--15">Services & Requests</h2>
    <p class="bw-mb--15">Prior to your special adventure, we can contact you to discuss the menu, activities, logistics and other concerns to provide the best experience possible.<br><br>
    Please add any notes below that will assist in the process.</p>
    <textarea class="bw-mb--15" rows="8" name="reservation_note" placeholder="Notes and requests" data-target="reservation-notes"></textarea>
    <div class="bw-select-container bw-mb--15">
        <select class="bw-primary" name="contact_method" data-target="contact-method">
            <option value="">Select a preferred contact method</option>
            <option value="Email">Email</option>
            <option value="Phone">Phone</option>
        </select>
        <span class="bw-icon--chevron-down"></span>
    </div>
`;