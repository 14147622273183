// alphabetized except when a template has a dependency

export const CLOSE_BTN_TEMPLATE = `
<div class="bw-close">
    <a href="#"><span class="bw-close__label">Close Booking</span><span class="bw-icon--x"></span></a>
</div>
`;

export const CLOSE_MODAL_TEMPLATE = `<a href="#" class="bw-button" data-ts-modal-close>Close</a>`;

export const CONTACT_INFO_FIELDS = `
    <div class="bw-sail-bg-container">
        <div class="bw-sail-bg"></div>
        <div class="bw-primary-contact-name"></div>
        <div class="bw-email-inputs">
            <input type="text" name="email" placeholder="Email*" data-target="email" data-has-confirm="[name='email_confirm']" required>
            <span class="bw-warning" style="display:none" data-target="warning-email"></span>
        </div>
    </div>
    <div>
        <input type="text" name="email_confirm" placeholder="Confirm Email*" data-target="email" data-has-confirm="[name='email']" required>
        <span class="bw-warning" style="display:none" data-target="warning-email-confirm"></span>
    </div>
    <div>
        <input type="text" name="phone" placeholder="Phone*" class="intltelinput" data-target="phone" data-has-confirm="[name='phone_confirm']" required>
        <span class="bw-warning" style="display:none" data-target="warning-phone"></span>
    </div>
    <div>
        <input type="text" name="phone_confirm" placeholder="Confirm Phone*" class="intltelinput" data-target="phone" data-has-confirm="[name='phone']" required>
        <span class="bw-warning" style="display:none" data-target="warning-phone-confirm"></span>
    </div>
    <span><label class="show" for="opt-in-sms"><input type="checkbox" id="opt-in-sms" class="bw-mr--10" checked /><span class="bw-opt-in-general">YES, I'm in! I agree to opt-in and receive important messages about my reservation. If I opt-out and uncheck the box, I WILL NOT receive important messages about my tour, and I WILL NOT receive boarding passes. Please be sure the box is checked to receive important messages regarding your reservation! Standard text message rates apply.</span><span class="bw-opt-in-waitlist bw-d-none">YES, I'm in! I agree to opt-in and receive important messages about this waitlist reservation. If I opt-out and uncheck the box, I WILL NOT be notified if space becomes available on this tour, and I will not receive messages for any future reservations with Capt Andy's. Please be sure the box is checked to receive improtant information about this waitlist reservation. Standard text message rates apply.</span></label></span>
    <br>
    <input type="hidden" name="opt_in" class="" value="1">
`;

export const DATE_ITEM_TEMPLATE = `
<a href="#" class="bw-date-item" data-date="">
    <span class="bw-date-item__content">
        <span class="day"></span>
        <span class="date"></span>
        <span class="month"></span>
    </span>
</a>`;

export const ERROR_MESSAGE = `
<div class="bw-errors bw-form-errors bw-mb--40" style="display:none;">
<h2>Oops.</h2>
<p>The information in the submitted fields did not process correctly.<br>Please fix the fields <span class="bw-error">highlighted in red</span> below.</p>
<p class="bw-fs-md">You can always <a href="[contact_us_url]">contact us</a> for assistance.</p>
</div>
`;

export const FOOTER_LOGO = `
    <img src="[logo_url]" width="300" alt="Capt. Andy's logo" />
`;

export const BW_VERSION = `
    <div class="bw-version" aria-hidden="true">v${BOOKING_VERSION}</div>
`;

export const FS_LOADING_TEMPLATE = '';

export const MODAL_TEMPLATE = `
    <section class="bw-modal" id="[modal_id]" aria-hidden="true">
        <div class="bw-modal__overlay" tabindex="-1" data-ts-modal-close>
            <div class="bw-modal__container" role="dialog" aria-modal="true">
                <main class="bw-modal__content"></main>
            </div>
        </div>
    </section>
`;

export const NAV_TEMPLATE = `
<div class="bw-nav-wrap">
    <div class="bw-nav">
        <a class="bw-nav__item bw-nav__item--search">
            <span class="bw-nav__item__circle"></span>
            <span class="bw-nav__item__label">Date <span class="bw-desktop-only">/ Guests</span></span>
        </a>
        <a class="bw-nav__item bw-nav__item--tours">
            <span class="bw-nav__item__circle"></span>
            <span class="bw-nav__item__label"><span class="bw-desktop-only">Select Tour</span><span class="bw-mobile-only">Available</span></span>
        </a>
        <a class="bw-nav__item bw-nav__item--info">
            <span class="bw-nav__item__circle"></span>
            <span class="bw-nav__item__label"><span class="bw-desktop-only">Your</span> Info</span>
        </a>
        <a class="bw-nav__item bw-nav__item--payment">
            <span class="bw-nav__item__circle"></span>
            <span class="bw-nav__item__label">Payment</span>
        </a>
        <a class="bw-nav__item bw-nav__item--confirmed">
            <span class="bw-nav__item__circle"></span>
            <span class="bw-nav__item__label">Confirm</span>
        </a>
    </div>
</div>
`;

export const PAGE_ERROR = `
<div class="bw-page-error">
    <h1>[error_title]</h1>
    <p>[error_message]</p>
</div>
`;


export const SELECTED_TOUR_TEMPLATE = `
<div class="bw-selected-tour">
    <p class="bw-selected-tour__message">[message]</p>
    <div class="bw-selected-tour__tour-info bw-gc-hide">
        <div class="bw-selected-tour__tour-content">
            <div class="bw-selected-tour__tour-details-line">
                <div class="bw-selected-tour__date bw-col">${DATE_ITEM_TEMPLATE}</div>
                <div class="bw-selected-tour__checkin bw-col">
                    <span class="bw-icon--clock bw-mb--6"></span>
                    <span class="bw-fs--sm bw-fs-md--md-3 bw-fw-bold">[checkin]</span>
                    <span class="bw-fs--xs-sm">Check in<span class="bw-desktop-only"> Time</span></span>
                </div>
                <div class="bw-selected-tour__location bw-col">
                    <span class="bw-icon--location bw-mb--6"></span>
                    <span class="bw-fs--sm bw-fs-md--md-3 bw-fw-bold bw-text-center">[location]</span>
                    <a href="[directions_uri]" target="_blank" class="bw-fs--xs-sm">Directions</a>
                </div>
                <div class="bw-selected-tour__tour-image bw-col">
                    <img src="[tour_image_url]" alt="[tour_name]">
                </div>
            </div>
            <div class="bw-selected-tour__tour-name-line">
                <div class="bw-selected-tour__name">[tour_name]</div>
                <div class="bw-selected-tour__tour-tags bw-tour-tags">[highlights]</div>
            </div>
        </div>
    </div>
    <div class="bw-selected-tour__price-list">
        <div class="bw-selected-tour__price-list__line">
            <span>[adult_count] [adult_label] [adult_price_base]</span>
            <span>[adult_price_total]</span>
        </div>
        <div class="bw-selected-tour__price-list__line bw-selected-tour__price-list__line--child" style="display:none">
            <span>[child_count] [child_label] [child_price_base]</span>
            <span>[child_price_total]</span>
        </div>
        <div class="bw-selected-tour__price-list__line bw-selected-tour__price-list__line--discount" style="display:none">
            <span>You Saved</span>
            <span class="bw-negative" data-target="discount">[discount]</span>
        </div>
        <div class="bw-selected-tour__price-list__line">
            <span>Tax/Fees</span>
            <span data-target="taxes-fees">[taxes_fees]</span>
        </div>
        <div class="bw-selected-tour__price-list__line bw-selected-tour__price-list__line--gift" style="display:none">
            <span>Gift Certificate</span>
            <span data-target="gift-code-amount">[gift_code_amount]</span>
        </div>
    </div>
    <div class="bw-selected-tour__total">
        <span>Total <span class="bw-text-transform-none">[payment_info_html]</span></span>
        <span data-target="total-price">[total_price]</span>
    </div>
    <div class="bw-selected-tour__notes" style="display:none">
        <div class="bw-selected-tour__passengers bw-flex-lg-basis-50" style="display:none">
            <div class="bw-mb--6 bw-fs--md"><strong>Passengers</strong></div>
            [passenger_names]
        </div>
        <div class="bw-selected-tour__contact bw-flex-lg-basis-50" style="display:none">
            <div class="bw-mb--6 bw-fs--md"><strong>[contact_label]</strong></div>
            Contact Name: <strong>[primary_name]</strong><br>
            Contact Email: <strong>[primary_email]</strong><br>
            Contact Phone: <strong>[primary_phone]</strong><br>
            Number of Guests: <strong>[guest_count]</strong><br>
            [notes]
        </div>
    </div>
</div>
`;

export const TOUR_CARD_TAG = `<span class="bw-tour-tag">[tag]</span>`;

export const LINK_EMAIL = '<a href="mailto:fun@napali.com">fun@napali.com</a>';
export const LINK_PHONE = '<a href="tel:+18083356833">808-335-6833</a>';
