import { get_for_booking as _get_trips } from 'data/tour-vessel';
import { get_settings as get_agency_settings } from 'data/agency';
import { get_tours } from 'booking-widget/v2/common/data';
import { get_calculated as get_calculated_prices } from 'data/tour-price';
import { post as post_res } from 'data/reservation';

import { 
    filter_data, 
    format_calculated_prices,
    set_placeholders,
    setup_selected_tour_day,
    show_error,
    hide_loading,
    show_loading,
} from 'booking-widget/v2/common/util';

import { form_to_object } from 'ui/data';
import { get as get_cache, cache } from 'utils/cache';
import { 
    convertISOtoDate, 
    to_system_date,
    get_latest_date,
} from 'utils/date';
import { 
    show_loading as _show_loading, 
    hide_loading as _hide_loading, 
} from 'ui/visibility';
import {
    FS_LOADING_TEMPLATE
} from 'booking-widget/v2/templates/shared';
import {
    WAITLIST_TEMPLATE,
    WAITLIST_SUCCESS_MESSAGE
} from 'booking-widget/v2/templates/waitlist';

import { setup_close_btn } from 'booking-widget/v2/widgets/nav';
import { setup_form as setup_contact_form, validate_form as validate_contact_form } from 'booking-widget/v2/widgets/contact-form';
import { is_possible_number, get_number } from 'booking-widget/v2/widgets/phone-input';
import { STEPS, show_fullscreen_modal } from 'booking-widget/v2/bw';
import { 
    init as init_pikaday, 
    set_datepicker_display as _set_datepicker_display,
    destroy as destroy_pikaday,
} from 'booking-widget/v2/widgets/datepicker';

import { 
    init as init_tourpicker, 
    get_guest_counts, 
    destroy_tourpicker 
}
from 'booking-widget/v2/widgets/tourpicker';

const BOOKING_STATUS_SOLD_OUT = 'sold-out';
const BOOKING_STATUS_AFTER_CUTOFF = 'cutoff';
const BOOKING_STATUS_AVAILABLE = 'available';
const BOOKING_STATUS_AGE = 'age-restricted';

export const load = (options, bw_events) => {
    let opts = $.extend(true, {
        trip_id: false,
        adult: 2,
        child: 0,
        toddler: 0,
    }, (options || {}));

    let widget_el = show_fullscreen_modal(opts.context_el);
    let is_submitting = false;

    show_loading(widget_el, true);

    let prices_params = { 
        data: { 
            trip_id: opts.trip_id, 
            adult_count: opts.adult,
            child_count: Number(opts.child) + Number(opts.toddler)
        }
    };
    let form_el = $('.bw-form', widget_el);
    setup_contact_form({ form_el: form_el });
    let flexible_dates_el, flexible_tours_el, radio_els, datepicker_el, tourpicker_el;
    let child_count = opts.child + opts.toddler;
    $.when(get_trip(opts.trip_id), get_tours(), get_calculated_prices(prices_params), get_agency_settings())
        .then(function(tv_data, tours_json, prices_json, settings_cached) {
            const tours_data = tours_json.data;
            bw_events.on_loaded(opts);
            hide_loading(widget_el, true);
            let tour = filter_data(tours_json.data, {
                id: tv_data.tour_id
            });
            if (tour && tour.length == 1) {
                tour = tour[0];
            }
            const waitlist_el = $(set_placeholders(WAITLIST_TEMPLATE, {
                selected_tour_params: {
                    message: 'You will be waitlisted for this tour.',
                    tv_data: tv_data,
                    tour: tour,
                    prices: format_calculated_prices(prices_json),
                    adult_count: opts.adult,
                    child_count: child_count,
                }
            }));
            widget_el.append(waitlist_el);
            
            if (child_count > 0) {
                $('.bw-selected-tour__price-list__line--child', widget_el).show();
            }

            let error_el = $('.bw-form-errors', widget_el);
            let start_dt = convertISOtoDate(tv_data.start_time);
            setup_selected_tour_day(waitlist_el, tv_data.start_time, false);

            setup_close_btn(widget_el, STEPS.waitlist.name, function() {
                destroy_pikaday(pikaday);
            });

            if (opts.child && opts.child > 0) {
                $('.bw-selected-tour__price-list__line--child', waitlist_el).removeClass('bw-d-none');
            }
            $('.bw-opt-in-general', widget_el).addClass('bw-d-none');
            $('.bw-opt-in-waitlist', widget_el).removeClass('bw-d-none');

            let form_el = $('.bw-form', widget_el);
            setup_contact_form({ form_el: form_el });

            radio_els = $('.bw-big-radios__radio', widget_el);
            datepicker_el = $('.bw-big-radios[data-key="flexible-dates"] a[data-value="yes"]', widget_el);
            tourpicker_el = $('.bw-big-radios[data-key="flexible-tours"] a[data-value="yes"]', widget_el);
            flexible_dates_el = $('input[name="flexible_dates"]', form_el);
            flexible_tours_el = $('input[name="flexible_tours"]', form_el);
            radio_els.off('click').on('click', function(e) {
                e.preventDefault();
                let was_selected = $(this).hasClass('bw-selected');
                if (was_selected) {
                    return;
                }
                let radio_container_el = $(this).closest('.bw-big-radios');
                if ($(this).attr('data-value') == 'no') {
                    let flexible_key = radio_container_el.attr('data-key');
                    if (flexible_key == 'flexible-dates') {
                        $('.bw-hidden-flexible[name="flexible_dates"]', form_el).val('');
                        populate_flexible_date_string();
                    }
                    else if (flexible_key == 'flexible-tours') {
                        $('.bw-hidden-flexible[name="flexible_tours"]', form_el).val('');
                        populate_flexible_tour_string();

                    }
                }
                $('.bw-selected', radio_container_el).removeClass('bw-selected');
                $(this).addClass('bw-selected');
            });
            $('.bw-big-checkboxes .bw-big-checkboxes__checkbox', widget_el).off('click').on('click', function(e) {
                e.preventDefault();
                let flexible_key = $(this).attr('data-key').replace('-', '_');
                let was_selected = $(this).hasClass('bw-selected');
                let new_val = was_selected ? 0 : 1;
                $('input[name="' + flexible_key + '"]', form_el).val(new_val);
                $(this).toggleClass('bw-selected');
            });

            let hide_fn = null;
            const pikaday_options = {
                datepicker_el: datepicker_el, 
                utc_offset: opts.utc_offset,
                start_time: opts.start_time,
                numberOfMonths: 1,
                keep_open: false,
                events: [],
                theme:'bw-waitlist-pika',
                onOpen: function() {
                    if (flexible_dates_el.val() == '') {
                        pikaday.config({
                            events: []
                        });
                        setSelectedDay(start_dt);
                    }
                },
                onSelect: function(date) {
                    setSelectedDay(date);
                    
                    // clear other values here, instead of on click/open of the control
                    // bc pikaday opens calendar on focus.  so only clear other vals if a day is selected.
                    $('.bw-hidden-flexible:not([name="flexible_dates"])', form_el).val('');

                    // hacky, but so is pikaday.
                    // pikaday hides the calendar after 100ms.  so overwrite the hide function until its done trying to hide.
                    if (hide_fn == null) {
                        hide_fn = pikaday.hide;
                    }
                    pikaday.hide = function() {};
                    setTimeout(function() {
                        pikaday.hide = hide_fn; 
                    }, 200);
                },
                onClose: function() {
                    populate_flexible_date_string();
                }
            };
        
            let pikaday = init_pikaday(pikaday_options);
            if (!pikaday || !datepicker_el.hasClass('pika-initialized')) {
                $(document)
                    .off('bw-pikaday-loaded')
                    .on('bw-pikaday-loaded', function() {
                        pikaday = init_pikaday(pikaday_options);
                    });
            }
            
            setup_tourpicker(tourpicker_el, tv_data, tours_json.data);

            $('form', widget_el).on('submit', function(e) {
                e.preventDefault();
                if (is_submitting) {
                    return;
                }
                
                error_el.hide();
                is_submitting = true;
                let errors = {};
                
                let form_data = form_to_object($('form', widget_el));
                form_data.tour_vessel_id = opts.trip_id;

                validate_contact_form(form_el, form_data, errors);

                if (!$.isEmptyObject(errors)) {
                    show_error(error_el, errors);
                    is_submitting = false;
                    return;
                }

                let first_name_el = $('input[name="contact_first_name"]', form_el);
                let last_name_el = $('input[name="contact_last_name"]', form_el);
                if (first_name_el.val().length == 0 || last_name_el.val().length == 0) {
                    // this should not show on normal conditions
                    show_error(error_el, {
                        contact_first_name: 'The first and last name of the contact is required. If you need assistance please contact the reservation desk.',
                        contact_last_name: '',
                    });
                    
                    is_submitting = false;
                    return;
                }

                let adult = parseInt(opts.adult) || 0;
                let child = parseInt(opts.child) || 0;
                let toddler = parseInt(opts.toddler) || 0;
                let child_count = child + toddler;

                form_data.contact_id = [];
                form_data.is_child = [];
                form_data.passenger_id = [];
                form_data.first_name = [];
                form_data.last_name = [];
                form_data.food_preference = [];
                form_data.is_child = [];
                const num_pax = adult + child + toddler;
                for (let idx = 0; idx < num_pax; idx++) {
                    if (idx == 0) {
                        form_data.first_name.push(first_name_el.val());
                        form_data.last_name.push(last_name_el.val());
                    }
                    else {
                        form_data.first_name.push('');
                        form_data.last_name.push('');
                    }
                    form_data.contact_id.push('');
                    form_data.passenger_id.push('');
                    form_data.food_preference.push('');
                }

                for (let i = 0; i < adult; i++ ) {
                    form_data.is_child.push('off');
                }
                for (let i = 0; i < (child + toddler); i++ ) {
                    form_data.is_child.push('on');
                }
                form_data.status = 'Waitlist';
                form_data.is_waitlisted = 'on';

                let flexible_date_vals = form_data.flexible_dates == '' ? [] : form_data.flexible_dates.split(',');
                let date_strings = '';
                if (form_data.flexible_dates.length > 0) {
                    form_data.waitlist_dates = flexible_date_vals.join(',');
                    form_data.waitlist_end_dtm = get_latest_date(flexible_date_vals);
                    date_strings = flexible_date_vals.map(function(d) {
                        return convertISOtoDate(d + ' 00:00:00').toLocaleDateString('en-US', { 
                            day: 'numeric', 
                            month: 'numeric', 
                            year:'numeric'
                        });
                    }).join('<br>');
                }
                else {
                    form_data.waitlist_dates = to_system_date(start_dt);
                    form_data.waitlist_end_dtm = to_system_date(start_dt);
                    date_strings = start_dt.toLocaleDateString('en-US', { 
                        day: 'numeric', 
                        month: 'numeric', 
                        year:'numeric'
                    });
                }
                form_data.waitlist_tour_ids = form_data.flexible_tours.split(',');

                show_loading(widget_el);
                post_res({
                    data: form_data,
                    on_done: function(json) {
                        is_submitting = false;
                        hide_loading(widget_el);
                        if (json.errors && json.errors.length > 0) {
                            show_error(error_el, json.errors);
                        }
                        else if (json.success == false) {
                            show_error(error_el, 'Your request could not be processed at this time.  Please try again.');
                        }
                        else {
                            $('.bw-waitlist', widget_el)
                                .addClass('success')
                                .css('background', `url(${URL_ROOT_PUBLIC}assets/img/banners/success.jpg)`)
                            ;
                            $('.bw-waitlist__header h1', widget_el).text('Mahalo');
                            $('.bw-waitlist__header p', widget_el).hide();

                            let passenger_counts = adult + ' adult' + (adult > 1 ? 's' : '');
                            if (child_count > 0) {
                                passenger_counts += ' ' + child_count;
                                passenger_counts += (child_count > 1) ? ' children' : ' child';
                            }


                            const tours = tours_data.filter(function(t) {
                                return form_data.flexible_tours.includes(t.id) || tv_data.tour_id == t.id;
                            }).map(function(t) { return t.name; }).join('<br>');

                            $('.bw-body', widget_el)
                                .empty()
                                .append(set_placeholders(WAITLIST_SUCCESS_MESSAGE, {
                                passenger_counts: passenger_counts,
                                dates: date_strings,
                                tours: tours
                            }));
                            // change close button
                            setup_close_btn(widget_el, '', function() {
                                destroy_pikaday(pikaday);
                            });
                            bw_events.on_waitlist_submit(form_data);
                        }
                    }
                });
            });

            function setSelectedDay(date) {
                const year = date.getFullYear();
                const month = date.getMonth() + 1;
                const day = date.getDate();
                const date_str = year
                    + '-' + month.toString().padStart(2, '0')
                    + '-' + day.toString().padStart(2, '0');
                const val = flexible_dates_el.val();
                let flexible_date_vals = val == '' ? [] : val.split(',');
                const is_adding = flexible_date_vals.indexOf(date_str) === -1;
                // set the events in pikaday
                if (is_adding) {
                    flexible_date_vals.push(date_str);
                }
                else {
                    flexible_date_vals = flexible_date_vals.filter(item => item !== date_str);
                }
                pikaday.config({
                    events: flexible_date_vals.map(date_string => convertISOtoDate(date_string + ' 00:00:00').toDateString())
                });

                // redraw
                pikaday.clear();

                // set our input
                flexible_dates_el.val(flexible_date_vals.join(','));
            }

            function populate_flexible_date_string() {
                const val = flexible_dates_el.val();
                const flexible_date_vals = val == '' ? [] : val.split(',');
                const total_selected = flexible_date_vals.length;
                const el = $('.flexible-date-string', datepicker_el);
                if (total_selected == 0) {
                    el.hide();
                }
                else {
                    el.text(total_selected + (total_selected > 1 ? ' dates ' : ' date ') + 'selected');
                    el.show();
                }
            }
        })


    function populate_flexible_tour_string() {
        const val = flexible_tours_el.val();
        const flexible_tour_vals = val == '' ? [] : val.split(',');
        const total_selected = flexible_tour_vals.length;
        const el = $('.flexible-tour-string', tourpicker_el);
        if (total_selected == 0) {
            el.hide();
        }
        else {
            el.text(total_selected + (total_selected > 1 ? ' other tours ' : ' other tour ') + 'selected');
            el.show();
        }
    }

    function set_selected_tours(checked_tours_el) {
        let vals = [];
        checked_tours_el.each(function() {
            vals.push(this.value);
        })
        const ids = vals.join(',');
        flexible_tours_el.val(ids);
    }
    function get_search_params() {
        let search_params = {
            trip_id: opts.trip_id
        };

        return search_params;
    }

    // this only returns the data, not the full json response
    // this is so tours can cache data it already got
    function get_trip(trip_id) {
        let d = $.Deferred();
    
        let cached = get_cache('tour-vessel-detail-' + trip_id);
        if (cached != false) {
            return d.resolve(cached);
        }

        _get_trips({
            id: trip_id,
            on_done: function(json) {
                cache('tour-vessel-detail-' + trip_id, json.data);
                d.resolve(json.data);
            }
        });
    
        return d.promise();
    }

    // get available tours w/ the capacity at the time of tv_data,
    function setup_tourpicker(tourpicker_el, tv_data, tours_data) {
        const _init_tourpicker = function(waitlist_tours_json) {
            const unique_tour_ids = [...new Set(waitlist_tours_json.data.map(function(tv) { 
                return tv.tour_id
            }))];
            const tours = tours_data.filter(function(t) {
                return unique_tour_ids.includes(t.id);
            });

            init_tourpicker({
                display_el: tourpicker_el,
                data: tours,
                on_hidden: function(checked_tours_el) {
                    // only set the value if the Yes box is still selected
                    if ($('.bw-big-radios[data-key="flexible-tours"] .bw-selected').attr('data-value') == 'yes') {
                        set_selected_tours(checked_tours_el);
                        populate_flexible_tour_string();
                    }
                    
                },
            });
        }
        const days_offset = 3;
        let adjusted_start_time = tv_data.start_time;
        let test_dt = new Date(tv_data.start_time);
        test_dt = test_dt.setDate(test_dt.getDate() - days_offset);
        if (test_dt < (new Date())) {
            let adjusted_start_dt = new Date();
            adjusted_start_dt.setDate(adjusted_start_dt.getDate() + days_offset);
            adjusted_start_time = to_system_date(adjusted_start_dt);
        }
        const waitlist_params = {
            days_offset: days_offset,
            start_time: adjusted_start_time,
            ne_tour_id: tv_data.tour_id,
            capacity_max: Number(opts.adult) + Number(opts.child) + Number(opts.toddler),
            on_done: function(waitlist_tours_json) {
                _init_tourpicker(waitlist_tours_json);
            }
        }
        let cache_key = 'booking-tours-' + JSON.stringify(waitlist_params);
        let cached = get_cache(cache_key);
        if (cached != false) {
            _init_tourpicker(cached);
        }
        _get_trips(waitlist_params);
    }
}

export const to_url_params = (search_params) => {
    let has_required_keys = [
        'trip_id',
        'adult',
        'child',
        'toddler'
    ].every((i) => search_params.hasOwnProperty(i));

    if (!has_required_keys) {
        return false;
    }

    return {
        step: STEPS.waitlist.name,
        trip_id: search_params.trip_id ? search_params.trip_id : 0,
        adult: search_params.adult,
        child: search_params.child,
        toddler: search_params.toddler
    }
}