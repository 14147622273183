import { 
    CLOSE_BTN_TEMPLATE, 
    NAV_TEMPLATE,
    FOOTER_LOGO,
    BW_VERSION,
    LINK_EMAIL,
    LINK_PHONE
} from 'booking-widget/v2/templates/shared';

export const CATEGORY_TEMPLATE = `
    <div class="bw-tours__category-[tour_category_id] bw-clear">
        <h2 class="bw-tours__category-header">
            [tour_category_name]
        </h2>
        <div class="bw-tours__list"></div>
    </div>
`;

export const CARD_TEMPLATE = `
    <div class="bw-tours__card [booking_status_class]" data-tour-id="[tour_id]" data-trip-id="[trip_id]">
        <div class="bw-tours__card__content">
            <div class="bw-tours__card__section--top">
                <div class="bw-tours__card__section--left">
                    <img class="bw-tours__card__img" src="" data-image="[tour_image_url]" alt="[tour_name] image">
                </div>
                <div class="bw-tours__card__section--middle">
                    <h3 class="bw-tours__card__title">[tour_name]</h3>
                    <div class="bw-tours__card__tour-tags bw-tour-tags bw-mb--6">
                        [highlights]
                    </div>
                    <p class="bw-tours__card__excerpt bw-desktop-only">
                        [excerpt]&nbsp;<a href="#" class="bw-tours__card__details-open">Details&hellip;</a>
                    </p>
                    <p class="bw-tours__card__pricing">
                        <span class="bw-tours__card__price">[total_cost]</span>
                        <span class="bw-tours__card__price-description">[total_cost_desc]</span>
                    </p>
                </div>
                <div class="bw-tours__card__section--right bw-tours__card__action">
                    <a href="#" class="bw-button bw-tours__button--book" data-tour-id="[tour_id]">Select</a>
                </div>
            </div>
            <div class="bw-tours__card__section--bottom">
                [tour_description]
                <div class="bw-tours__card__links">
                    <div class="bw-fw-bold bw-mb--1em">Have questions about our tours?</div>
                    <a href="[faq_url]">Capt Andy's FAQs</a>
                    <a href="[tour_url]">Tour Detail Page</a>
                    <a href="[photos_url]">Photos and Videos</a>
                </div>
                
            </div>
            <div class="bw-tours__card__action bw-mobile-only">
                <a href="#" class="bw-button bw-tours__button--book" data-tour-id="[tour_id]" target="_blank">Select</a>
            </div>
            <a href="#" class="bw-tours__card__details-close">Minimize Details <span class="bw-icon--x bw-icon--x--thin bw-desktop-only"></span></a>
        </div>
    </div>
`;

export const UNAVAILABLE_TEMPLATE = `
    <div class="bw-tours__unavailable">
        <div class="bw-tours__unavailable__intro">
            <span class="bw-tours__unavailable__header">[header]</span>
            <span class="bw-tours__unavailable__subheader">[subheader]</span>
        </div>
        <div class="bw-tours__unavailable__days">
            <div style="columns: 2;">[days]</div>
            <a href="#" class="bw-tours__waitlist-link bw-mt--15">Waitlist?</a>
        </div>
    </div>
`;

export const TOURS_TEMPLATE = `
<div class="bw bw-tours">
    <div class="bw-header">
        ${NAV_TEMPLATE}
        ${CLOSE_BTN_TEMPLATE}
        <h1 class="bw-text-center bw-mt--40 bw-mb--12"><span class="bw-mobile-only">Availability</span><span class="bw-desktop-only">[title]</h1>
    </div>
    <div class="bw-body">
        <div class="bw-sail-bg"></div>
        <div class="bw-date-carousel">
            <a href="#" class="bw-date-carousel__button bw-date-carousel__button--prev bw-icon--prev"><span class="bw-sr">Prev</span></a>
            <div class="bw-date-carousel-view">
                <div class="bw-date-carousel__items"></div>
            </div>
            <a href="#" class="bw-date-carousel__button bw-date-carousel__button--next bw-icon--next"><span class="bw-sr">Next</span></a>
        </div>
        <div class="bw-results"></div>
    </div>
    <div class="bw-footer">
        ${FOOTER_LOGO}
        ${BW_VERSION}
    </div>
</div>
`;

export const NO_RESULTS_TEMPLATE = `
    <div class="bw-tours__no-results">
        <h2>No Tours Found</h2>
        <p>Please try refining your search criteria or contact us at ${LINK_EMAIL} or ${LINK_PHONE} for help.</p>
    </div>
`;
export const NO_RESULTS_TEMPLATE_CUSTOM = `
    <div class="bw-tours__no-results">
        <p>Please contact us at ${LINK_EMAIL} or ${LINK_PHONE} to customize your tour. </p>
    </div>
`;