import { 
    CLOSE_BTN_TEMPLATE, 
    NAV_TEMPLATE,
    SELECTED_TOUR_TEMPLATE,
    FOOTER_LOGO,
    ERROR_MESSAGE,
    LINK_EMAIL,
    LINK_PHONE
} from 'booking-widget/v2/templates/shared';

export const TC_AGREE_CHECKBOXES = `
<label class="bw-highlight--checkbox bw-mb--30" style="display: inline-block;">
    <input type="checkbox" name="tc_agree" value="1" class="bw-mr--05em" oninvalid="this.setCustomValidity(\'This is required to proceed.\')" oninput="this.setCustomValidity(\'\')" required />I agree to the terms and conditions listed above.
</label>
`;
export const PAYMENT_TEMPLATE = `
<div class="bw bw-info">
    <div class="bw-header">
        ${NAV_TEMPLATE}
        ${CLOSE_BTN_TEMPLATE}
        <h1 class="bw-text-center bw-mb--0"><span class="bw-desktop-only">Secure</span> Payment <span class="bw-desktop-only bw-gc-hide">&amp; Terms</span></h1>
    </div>
    <div class="bw-body">
        ${SELECTED_TOUR_TEMPLATE}
        <p class="bw-best-price">
            You're booking direct with Capt Andy's - <span>Best Price Guaranteed</span>
        </p>
        <div class="bw-form-wrap">
            ${ERROR_MESSAGE}
            <form class="bw-form" action="">
                <div class="bw-form--payment">
                    <h2 class="bw-mt--30">Payment Information</h2>
                    <div class="bw-row bw-red payment-error-message" style="display:none"></div>
                    <div class="bw-row">
                        <label class="bw-sr" for="cc_first_name">*First Name</label>
                        <input type="text" id="cc_first_name" name="cc_first_name" placeholder="*Card First Name" data-target="cc-first-name" required>
                        <label class="bw-sr" for="cc_last_name">*Last Name</label>
                        <input type="text" id="cc_last_name" name="cc_last_name" placeholder="*Card Last Name" data-target="cc-last-name" required>
                    </div>
                    <div class="bw-row">
                        <label for="cc_number" class="bw-sr">Credit Card Number</label>
                        <input placeholder="*XXXX XXXX XXXX XXXX" type="text" id="cc_number" name="cc_number" autocomplete="cc-number" value="" required>
                    </div>
                    <div class="bw-row">
                        <label for="cc_exp" class="bw-sr">Expiration (MM/YY)</label>
                        <input type="text" id="cc_exp" name="cc_exp" placeholder="*Expiration (MM/YY)" data-target="cc-exp" required>
                        <label for="cvv_code" class="bw-sr">CVV</label>
                        <input type="text" id="cvv_code" name="cc_cvv" placeholder="*CVV" data-target="cc-cvv" maxlength="4" required>
                        <label for="cc_zip" class="bw-sr">CVV</label>
                        <input type="text" id="cc_zip" name="cc_zip" placeholder="*Billing zip code" data-target="cc-zip" required>
                    </div>
                </div>
    
                <div class="bw-terms-conditions bw-mt--30 bw-gc-hide">
                    <h2><span class="bw-d-block bw-d-sm-inline">Important:</span> Terms &amp; Conditions</h1>
                </div>

                <input type="hidden" id="cardholder_name">
                <input type="hidden" id="exp_month">
                <input type="hidden" id="exp_year">
                <input type="submit" class="bw-button bw-form-submit" data-original="Continue to Payment" value="Complete Booking">
            </form>
        </div>
    </div>
    <div class="bw-footer">
        ${FOOTER_LOGO}
    </div>
</div>

<div class="bw-food-preference-popup-container" style="display: none"></div>
`;

export const PAYMENT_ERROR_RES_SUCCESS = `Your payment has gone through, but your reservation is still pending.  Please email ${LINK_EMAIL} or call ${LINK_PHONE} with your reservation number: `;


export const PAYMENT_ERROR_GENERIC = `Your payment could not be processed.  Please ensure all information is correct and try again.`;